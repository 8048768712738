import { CommonModule, DOCUMENT, isPlatformBrowser, isPlatformServer, registerLocaleData } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, Inject, InjectionToken, OnInit, Optional, PLATFORM_ID, inject, makeStateKey, signal } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { fromEvent } from 'rxjs';
import { AuthGuardService } from './services/auth-guard.service';
import { environment } from '../environments/environment';

import { CookiesService } from './services/cookies.service';
import { DateService } from './services/date.service';
import { ChatService } from './services/chat.service';
import { Partner } from './models/partner/IPartner';
import { PartnerService } from './services/partner.service';
import { Router, RouterModule } from '@angular/router';
import { MEDIA_TOKEN, PARTNER_TOKEN } from 'tockens';
// import { ScreenService } from './services/screen.service';
import { EncryptionService } from './services/encyption.service';
import { TransferState } from '@angular/core';
// import { SwUpdate } from '@angular/service-worker';
// import { PagerReloadComponent } from './components/pagereload/pagereload.component';
// import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FormsModule } from '@angular/forms';
import { ChatModule } from './components/chat/chat.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CookieModule } from './components/cookie/cookie.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import localede from '@angular/common/locales/de';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OvermenuComponent } from './components/overmenu/overmenu.component';
import { NewlettermodalComponent } from './components/modals/newlettermodal/newlettermodal.component';
import { MatDialog } from '@angular/material/dialog';
import { GoogleService } from './services/google.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Meta } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
const STATE_KEY_PARTNER = makeStateKey('partnerdata');
registerLocaleData(localede);
@Component({
    selector: 'clever-reisen',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ChatModule,
        OvermenuComponent,
        MatFormFieldModule,
        CookieModule,
        MatTooltipModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule
    ]
})
export class AppComponent implements OnInit, AfterViewInit {
    title = '';
    isBrowser = signal<boolean>(false);
    showScrollTopButton = signal<boolean>(false);
    showchatpanel = signal<boolean>(false);
    showChat = signal<boolean>(false);
    hidenNavbar = signal<boolean>(false);
    smallMode = signal<boolean>(false);
    showChatAgent = signal<boolean>(true);
    displaySite = signal<boolean>(true);
    pass: string = '';
    partnerid: string = '';
    protected secret: string = 'cleveracvis';
    protected curentPartner: Partner;
    protected lastposition: number = 0;
    destroyRef = inject(DestroyRef);
    firstloading: boolean = false;

    constructor(
        protected adapter: DateAdapter<any>,
        @Inject(PLATFORM_ID) protected platformId: any,
        @Inject(DOCUMENT) protected document: any,
        protected cookieService: CookiesService,
        protected dateService: DateService,
        protected chatService: ChatService,
        protected authguard: AuthGuardService,
        protected partnerService: PartnerService,
        // protected screenService: ScreenService,
        protected encService: EncryptionService,
        protected router: Router,
        // private bottomSheet: MatBottomSheet
        // @Optional() private update: SwUpdate,
        private dialog: MatDialog,
        protected state: TransferState,
        protected cdr: ChangeDetectorRef,
        protected metaService: Meta,
        protected googleServie: GoogleService,
        protected mediaObserver: BreakpointObserver,
        @Optional() @Inject(PARTNER_TOKEN) private partner_id: InjectionToken<string>,
        @Optional() @Inject(MEDIA_TOKEN) private media_token: string
    ) {
        this.isBrowser.set(isPlatformBrowser(this.platformId));
        this.smallMode.set(this.media_token === 'xs');
        this.partnerid = this.partner_id?.toString();
        if (!this.partnerid && this.isBrowser()) {
            this.partnerid = window.localStorage.getItem('partnerid');
        }
    }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    ngOnInit(): void {
        this.initMedia();
        this.getPartner();
        this.checkHidennavbar();
    }


    async initMedia() {
        if (this.mediaObserver.isMatched(Breakpoints.XSmall)) {
            this.smallMode.set(true);
        }
        if (this.mediaObserver.isMatched(Breakpoints.Small)) {
            this.smallMode.set(true);
        }
        if (this.mediaObserver.isMatched(Breakpoints.Medium)) {
            this.smallMode.set(true);
        }
        if (this.mediaObserver.isMatched(Breakpoints.Large)) {
            this.smallMode.set(false);
        }
        if (this.mediaObserver.isMatched(Breakpoints.XLarge)) {
            this.smallMode.set(false);
        }
        this.cdr.detectChanges();
    }

    async checkHidennavbar() {
        this.authguard.gethidenHeaderFooter$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((e: boolean) => {
            this.hidenNavbar.set(e);
            this.cdr.detectChanges();
        });
    }

    async getPartner() {
        const partres = this.state.get(STATE_KEY_PARTNER, <any>{});
        if (partres?.data?.length > 0) {
            const tokenFromUI = partres.data?.slice(-16);
            const data = partres.data?.slice(0, (partres.data.length - 16));
            this.curentPartner = JSON.parse(this.encService.deencrypt(tokenFromUI, data));
        }
        this.state.set(STATE_KEY_PARTNER, <any>{}); // remove content for next loading
        // const start = Date.now();
        if (this.curentPartner?.partnerid?.length > 0) {
            this.showChat.set(!this.curentPartner.hideChat);
            this.cdr.detectChanges();
            this.initPartnerData();
        } else {
            this.partnerService.request({ partnerid: this.partnerid }, 'checkpartner').pipe(
                takeUntilDestroyed(this.destroyRef)
            ).subscribe((prtn: any) => {
                if (prtn?.data?.length > 0) {
                    const tokenFromUI = prtn.data?.slice(-16);
                    const data = prtn.data?.slice(0, (prtn.data.length - 16));
                    this.curentPartner = JSON.parse(this.encService.deencrypt(tokenFromUI, data));
                    this.showChat.set(!this.curentPartner.hideChat && !this.detectRobot());
                    if (isPlatformServer(this.platformId)) {
                        this.state.set(STATE_KEY_PARTNER, <any>prtn);
                    }
                    this.initPartnerData();
                } else {
                    this.router.navigate(['notfound']);
                }
            });
        }
    }

    async initPartnerData() {
        this.authguard.checkPartnerDatas(this.curentPartner);
        // this.initGoogleapi(this.curentPartner);
        if (this.isBrowser()) {
            this.secret = this.curentPartner.partnersite;
            if (this.secret?.length > 0 && window.localStorage.getItem(this.curentPartner.partnerid + '_pass') !== this.secret) {
                this.displaySite.set(false);
            }
            if (this.showChat()) {
                this.chatService.connectToServer();
            }
            this.checkSendScreen();
            this.checknewsletterpopup();
            this.gtagdef(this.curentPartner);
            this.adsensemeta(this.curentPartner);
            this.cdr.detectChanges();
        }
    }

    async gtagdef(prt) {
        if (prt.googleanalyticstrackingid?.length > 0) {
            this.googleServie.loadgoogleanalyticsdefault(prt.googleanalyticstrackingid, prt.googleanalyticstrackingwitouttagmanager, prt.hidedefaultcookie);
        }
        if (prt.googletagmgid?.length > 0) {
            this.googleServie.loadGoogleTagManager(prt.googletagmgid, prt.hidedefaultcookie);
        }
        setTimeout(() => {
            this.googleServie.updateGoogleAnalyticsdef();
            this.checkCookies();
            this.initGlobalDatas();
        }, 500);
    }



    async adsensemeta(prt) {
        if (prt.googleadsenseid?.length > 0) {
            this.metaService.updateTag({ name: 'google-adsense-account', content: prt.googleadsenseid });
        }
    }

    async initGoogleapi(currentPartner) {
        if (currentPartner.googleapikey?.length > 0 && isPlatformBrowser(this.platformId) && !this.detectRobot()) {
            const gaScript = this.document.createElement('script');
            gaScript.setAttribute('defer', '');
            gaScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${currentPartner.googleapikey}&libraries=places&callback=Function.prototype`);

            this.document.documentElement.firstChild.appendChild(gaScript);
        }
    }

    async checknewsletterpopup() {
        if (this.curentPartner.newsletterpopupaktiv) {
            const popupaktiv = window.localStorage.getItem(this.curentPartner.partnerid + '_newsletterpopupaktiv');
            if (popupaktiv !== '1') {
                if (this.smallMode) {
                    const myppsubs = fromEvent(window, 'scroll').pipe(
                        takeUntilDestroyed(this.destroyRef)
                    ).subscribe((_) => {
                        if (window.scrollY > 500) {
                            this.dialog.open(NewlettermodalComponent, {
                                width: !this.smallMode() ? '800px' : '100%',
                                height: !this.smallMode() ? '' : '100vh',
                                maxWidth: '100%',
                                panelClass: 'customModal',
                                data: {
                                    email: '',
                                    unsubscribe: false
                                }
                            });
                            window.localStorage.setItem(this.curentPartner.partnerid + '_newsletterpopupaktiv', '1');
                            myppsubs.unsubscribe();
                        }
                        this.cdr.detectChanges();
                    });
                } else {
                    const myppsub = fromEvent(document.querySelector('body'), 'mouseleave').pipe(
                        takeUntilDestroyed(this.destroyRef)
                    ).subscribe((_) => {
                        this.dialog.open(NewlettermodalComponent, {
                            width: !this.smallMode() ? '800px' : '100%',
                            height: !this.smallMode() ? '' : '100vh',
                            maxWidth: '100%',
                            panelClass: 'customModal',
                            data: {
                                email: '',
                                unsubscribe: false
                            }
                        });
                        window.localStorage.setItem(this.curentPartner.partnerid + '_newsletterpopupaktiv', '1');
                        // console.log('unsbub popup');
                        myppsub.unsubscribe();
                        this.cdr.detectChanges();
                    });
                }
            }
        }
    }

    async checkSendScreen() {
        // this.chatService.checkScreen()
        //     .pipe(
        //         takeUntilDestroyed(this.destroyRef),
        //     ).subscribe((data: { show: boolean, from: any }) => {
        //         // protected screenService: ScreenService
        //         if (data.show && data?.from?.username?.length > 0) {
        //             this.screenService.sharescreen((canvas) => {
        //                 // console.log(canvas)
        //                 this.chatService.sendScreen(data.from, canvas);
        //             });
        //         }
        //     });
    }

    openChat(): void {
        this.showchatpanel.set(true);
    }

    closeChat(): void {
        this.showchatpanel.set(false);
    }

    closeChatAgent(): void {
        this.showChatAgent.set(false);
    }

    async listenOpenChat() {
        this.chatService.openchat$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((open) => {
            if (open) {
                this.openChat();
            }
        });
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId) && !this.detectRobot()) {
            this.appendStyle('late_styles.css');
            this.appendScript('socket.io.js');
            this.appendScript('lazysizes.min.js');
            this.appendScript('crypto-js.js');
            this.appendScript('moment.min.js').then(loaded => {
                if (loaded) {
                    this.appendScript('moment-timezone.min.js');
                }
            });
        }
    }

    appendScript(name: string) {
        return new Promise((resolve) => {
            const script = this.document.createElement('script');
            script.src = name;
            script.async = true;
            this.document.head.appendChild(script);
            script.onload = () => {
                resolve(true);
            };
        });
    }

    appendStyle(name: string) {
        const style = this.document.createElement('link');
        style.rel = 'preload';
        style.as = 'style';
        style.type = 'text/css';
        style.href = name;
        this.document.head.appendChild(style);
        const style1 = this.document.createElement('link');
        style1.rel = 'stylesheet';
        style1.type = 'text/css';
        style1.href = name;
        this.document.head.appendChild(style1);
    }

    async checkCookies() {
        if (this.secret?.length > 0) {
            if (this.displaySite()) {
                if (environment.production) {
                    if (!this.detectRobot()) {
                        this.cookieService.checkCookis(
                            this.curentPartner.googleanalyticstrackingid,
                            this.curentPartner.googletagmgid,
                            this.curentPartner.googleadsenseid,
                            this.curentPartner.googlemapid,
                            this.curentPartner.facebookpixelid,
                            this.curentPartner.matomoid,
                            this.curentPartner.matomositeid,
                            this.curentPartner.salesmanagoid,
                            this.curentPartner.awinid,
                            this.curentPartner.partnerid,
                            this.curentPartner.hidedefaultcookie,
                            this.curentPartner.usercentricsid,
                        );
                    }
                }
            }
        } else {
            if (environment.production) {
                if (!this.detectRobot()) {
                    this.cookieService.checkCookis(
                        this.curentPartner.googleanalyticstrackingid,
                        this.curentPartner.googletagmgid,
                        this.curentPartner.googleadsenseid,
                        this.curentPartner.googlemapid,
                        this.curentPartner.facebookpixelid,
                        this.curentPartner.matomoid,
                        this.curentPartner.matomositeid,
                        this.curentPartner.salesmanagoid,
                        this.curentPartner.awinid,
                        this.curentPartner.partnerid,
                        this.curentPartner.hidedefaultcookie,
                        this.curentPartner.usercentricsid,
                    );
                }
            }
        }
    }

    async initGlobalDatas() {
        this.listenOpenChat();
        this.dateService.initCals();
        fromEvent(window, 'scroll').pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((e) => {
            this.lastposition = window.scrollY;
            this.checkScrollPos(this.lastposition);
        });

        this.adapter.setLocale('de-DE');
    }

    detectRobot(): boolean {
        if (this.isBrowser()) {
            const robots = new RegExp(([
                /bot/, /spider/, /crawl/, // GENERAL TERMS
                /APIs-Google/, /AdsBot/, /Googlebot/, // GOOGLE ROBOTS
                /mediapartners/, /Google Favicon/, /GOOGLE/,
                /FeedFetcher/, /Google-Read-Aloud/,
                /DuplexWeb-Google/, /googleweblight/, /SISTRIX/,
                /bing/, /yandex/, /baidu/, /duckduck/, /yahoo/, // OTHER ENGINES
                /ecosia/, /ia_archiver/, /cortex/,
                /facebook/, /instagram/, /pinterest/, /reddit/, // SOCIAL MEDIA
                /slack/, /twitter/, /whatsapp/, /Whatsapp/, /youtube/,
                /semrush/, // OTHER
            ] as RegExp[]).map((r) => r.source).join('|'), 'i'); // BUILD REGEXP + "i" FLAG

            return robots.test(navigator.userAgent);
        } else {
            return true;
        }
    }

    checkPass(ev) {
        if (ev.keyCode === 13 && this.pass === this.secret) {
            window.localStorage.setItem(this.curentPartner.partnerid + '_pass', this.secret);
            this.document.body.style.overflow = '';
            this.displaySite.set(true);
        } else if (ev.keyCode === 13) {
            this.pass = '';
        }
        this.cdr.detectChanges();
    }

    checkScrollPos(lastposition) {
        // faire quelque chose avec la position du scroll
        if (lastposition >= 500) {
            this.showScrollTopButton.set(true);
        } else {
            this.showScrollTopButton.set(false);
        }
    }
}
